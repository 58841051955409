import { useRef, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

export const useFetchApi = () => {
  const fetchApiRef = useRef();
  const [loading, setLoading] = useState(false);
  const { oktaAuth } = useOktaAuth();

  if (!fetchApiRef.current) {
    fetchApiRef.current = (() => {
      let timeout;
      return (url, { params, ...options } = {}) => {
        return new Promise((resolve, reject) => {
          clearTimeout(timeout);

          const currTimeout = setTimeout(async () => {
            options.method = options.method ?? 'GET';

            if ('GET' === options.method) {
              url += '?' + new URLSearchParams(params).toString();
            } else {
              options.body = JSON.stringify(params);
            }

            options.headers = {
              Authorization: oktaAuth.getAccessToken(),
              ...options?.headers,
            };

            setLoading(true);
            try {
              const response = await fetch(process.env.REACT_APP_API_URL + url, options);
              if (currTimeout !== timeout) return reject({ status: 'cancelled' });
              if (response.status > 399) throw response;
              resolve(await response.json());
              setLoading(false);
            } catch (error) {
              console.error(error);
              oktaAuth.signOut();
            }
          });

          timeout = currTimeout;
        });
      };
    })();
  }

  return { fetchApi: fetchApiRef.current, loading };
};
