import React from 'react';

import { useClassName } from 'hooks';
import style from './style.module.scss';

export const DataTable = ({ className: classNameProp, header = [], body = [] }) => {
  const [className] = useClassName('nj-table nj-table--hover', style.TableMain, classNameProp);
  return (
    <table className={className}>
      <thead>
        <tr>
          {header.map((label, index) => (
            <th scope="col" key={index}>
              {label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {body.map((row, index) => (
          <tr key={index}>
            {row.map((content, key) => (
              <td key={key}>{content}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
