import React, { useEffect, useRef } from 'react';
import { HighCharts } from 'components';

const initHC = {
  chart: {
    type: 'column',
    height: 350,
  },
  title: {
    text: 'Prix du kWh (€)',
    margin: 40,
  },
  xAxis: {
    categories: [
      '0h',
      '1h',
      '2h',
      '3h',
      '4h',
      '5h',
      '6h',
      '7h',
      '8h',
      '9h',
      '10h',
      '11h',
      '12h',
      '13h',
      '14h',
      '15h',
      '16h',
      '17h',
      '18h',
      '19h',
      '20h',
      '21h',
      '22h',
      '23h',
    ],
    labels: {
      rotation: -45,
    },
  },
  yAxis: {
    labels: {
      format: '{text} €',
    },
    title: {
      text: null,
    },
    maxPadding: 0.01,
  },
  plotOptions: {
    column: {
      events: {
        legendItemClick: () => false,
      },
      stacking: 'normal',
    },
    spline: {
      events: {
        legendItemClick: () => false,
      },
    },
  },
  legend: {
    accessibility: {
      enabled: false,
    },
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'top',
    floating: true,
    itemDistance: 10,
    navigation: {
      enabled: false,
    },
    x: 15,
    y: 25,
  },
  tooltip: {
    shared: true,
    valueDecimals: 2,
    valueSuffix: ' €',
  },
  credits: {
    enabled: false,
  },
  series: [
    { name: 'Option Temps Réel', type: 'column', color: '#008ACD', data: [] },
    {
      name: 'Offre Référence',
      type: 'spline',
      color: '#00141E',
      data: [],
      marker: {
        enabled: false,
      },
    },
  ],
};

export const DayliGraph = ({ series }) => {
  const hc = useRef();
  useEffect(() => {
    series.forEach((s, i) => hc.current.chart.series[i].setData(s, false));
    hc.current.chart.redraw();
  }, [series]);

  return <HighCharts ref={hc} init={initHC} />;
};
