import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useOktaAuth } from '@okta/okta-react';
import { useFetchApi } from 'hooks';
import { roundNumber } from 'helpers';
import { AlertOverlap, Navbar, Selector, Spinner, Switch, SwitchViewData } from 'components';
import { ConsumGraph } from './ConsumChart';
import { ConsumDataTable } from './ConsumDataTable';

import style from './style.module.scss';

const plotIdByDuration = {
  d: (date) => date.hour(),
  w: (date) => date.date(),
  M: (date) => date.date(),
};

export const Consumption = () => {
  const hc = useRef();
  const [selector, setSelector] = useState({});
  const [bodyData, setBodyData] = useState([]);
  const [isEurView, setIsEurView] = useState(false);
  const [conso, setConso] = useState([]);
  const { fetchApi, loading } = useFetchApi();
  const { authState } = useOktaAuth();

  const onChange = (startDate, endDate, duration) => {
    setSelector({ startDate, endDate, duration });
  };

  const fetchConso = () => {
    if (!fetchApi || !selector.startDate || !selector.endDate) return;
    fetchApi('/conso', {
      params: {
        from: selector.startDate.unix(),
        to: selector.endDate.unix(),
        uid: authState?.accessToken?.claims?.uid,
        refresh: selector.endDate < dayjs() ? selector.endDate.startOf('d').unix() : dayjs().startOf('h').unix(),
      },
    })
      .then((json) => {
        const remap =
          json.map((v) => {
            v.date = dayjs(v.date);
            return v;
          }) || [];

        setConso(remap);
      })
      .catch((error) => {
        if (error.status === 'cancelled') {
          console.error(error);
        }
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchConso, [fetchApi, selector.startDate, selector.endDate]);

  const computeData = () => {
    const { startDate, duration } = selector;
    const data = new Map();

    if (!startDate) return;

    for (const curr of conso) {
      const plotId = plotIdByDuration[duration](curr.date);
      const [
        ,
        consoWh = 0,
        totalDynamique = 0,
        totalReference = 0,
        coutDynamique = 0,
        coutReference = 0,
        acheminement = 0,
      ] = data.get(plotId) || [];
      const timeFormatted =
        'd' === duration
          ? curr.date.format('H') + 'h'
          : curr.date.format('ddd')[0].toUpperCase() + curr.date.format('ddd D').slice(1);
      data.set(plotId, [
        timeFormatted,
        consoWh + curr.consoWh,
        totalDynamique + curr.coutDynamique,
        totalReference + curr.coutReference,
        coutDynamique + curr.fournitureDynamique,
        coutReference + curr.fournitureReference,
        acheminement + curr.acheminement,
      ]);
    }

    setBodyData(
      Array.from(data.values()).map(
        ([timeFormatted, consoWh, totalDynamique, totalReference, coutDynamique, coutReference, acheminement]) => [
          timeFormatted,
          roundNumber(consoWh, 0),
          ...[totalDynamique, totalReference, coutDynamique, coutReference, acheminement].map(
            (v) => roundNumber(v).toFixed(2) + ' €'
          ),
        ]
      )
    );

    if (!hc?.current?.chart) return;
    const computed = Array.from(data.values()).reduce(
      (acc, curr) => {
        const [timeFormatted, consoWh, totalDynamique, totalReference] = curr;

        acc[0].push([timeFormatted, consoWh]);
        acc[1].push([timeFormatted, totalDynamique]);
        acc[2].push([timeFormatted, totalReference]);

        return acc;
      },
      [[], [], []]
    );

    if (isEurView) {
      if (!hc.current.chart.series[1]) {
        // update label of the first seire
        hc.current.chart.series[0].update(
          {
            name: 'Option Temps Réel',
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' €',
            },
          },
          false
        );
        // ad series to compare the first one
        hc.current.chart.addSeries(
          {
            name: 'Offre Référence',
            type: 'spline',
            color: '#00141E',
            data: [],
            marker: {
              enabled: false,
            },
          },
          false
        );
      }

      hc.current.chart.title.update(
        {
          text: 'Consommation (€)',
        },
        false
      );
      hc.current.chart.yAxis[0].update(
        {
          labels: {
            format: '{text} €',
          },
        },
        false
      );

      hc.current.chart.series[0].setData(computed[1], false);
      hc.current.chart.series[1].setData(computed[2]);
    } else {
      if (hc.current.chart.series[1]) {
        hc.current.chart.series[1]?.remove(false);
        hc.current.chart.series[0].update(
          {
            name: 'Option Temps Réel',
            tooltip: {
              valueDecimals: 0,
              valueSuffix: ' kWh',
            },
          },
          false
        );
      }

      hc.current.chart.title.update(
        {
          text: 'Consommation (kWh)',
        },
        false
      );
      hc.current.chart.yAxis[0].update(
        {
          labels: {
            format: '{text} kWh',
          },
        },
        false
      );
      hc.current.chart.series[0].setData(computed[0]);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(computeData, [conso, isEurView, selector.duration]);

  return (
    <>
      <Selector onChange={onChange} />
      <main>
        <SwitchViewData onChange={computeData} dataComponent={<ConsumDataTable body={bodyData} />}>
          <Switch
            onChange={setIsEurView}
            className={style.SwitchClass}
            checked={isEurView}
            leftOption="kWh"
            rightOption="EUR"
          />
          <ConsumGraph ref={hc} />
        </SwitchViewData>
        {!loading && !conso.length && <AlertOverlap>Pas de données disponibles</AlertOverlap>}
        {loading && <Spinner />}
      </main>
      <Navbar />
    </>
  );
};
