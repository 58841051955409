import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import Highcharts from 'highcharts';

const HighChartsFunc = ({ key, init }, ref) => {
  const chart = useRef();

  useEffect(() => {
    chart.current = Highcharts.chart('chart-container', init);

    return () => {
      chart.current.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    get chart() {
      return chart.current;
    },
  }));

  return <div id="chart-container" />;
};

export const HighCharts = forwardRef(HighChartsFunc);
