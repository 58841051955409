export const oktaAuthConfig = {
  issuer: process.env.REACT_APP_OKTA_BASE_URL + '/oauth2/default',
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  tokenManager: {
    storage: 'localStorage',
    autoRenew: true,
  },
};

export const oktaSignInConfig = {
  baseUrl: process.env.REACT_APP_OKTA_BASE_URL,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  authParams: {
    // If your app is configured to use the Implicit flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to uncomment the below line
    // pkce: false
    scopes: ['openid', 'email', 'offline_access'],
  },
  // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
  logo: '/okta-logo.png',
  language: 'fr',
};
