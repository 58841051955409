import React from 'react';

import { useClassName } from 'hooks';

import style from './style.module.scss';

export const FabButton = ({ className: propClassName, icon, onClick }) => {
  const [className] = useClassName('nj-fab nj-fab--light', style.Fab, propClassName);
  return (
    <>
      <button className={className} onClick={onClick} type="button">
        <i className="material-icons">{icon}</i>
      </button>
    </>
  );
};
