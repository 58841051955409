import React from 'react';
import style from './style.module.scss';

export const Card = ({ className, preTitle, postTitle }) => {
  const classN = ['nj-card', style.Card, className].filter(Boolean).join(' ');
  return (
    <span className={classN}>
      <div className="nj-card__body">
        <p className="nj-card__title ic-pretitle">{preTitle}</p>
        <p className="nj-card__title ic-posttitle">{postTitle}</p>
      </div>
    </span>
  );
};
