import React, { forwardRef } from 'react';
import { HighCharts } from 'components';

const initHC = {
  chart: {
    type: 'column',
    height: 350,
  },
  title: {
    text: 'Consommation (kWh)',
    margin: 40,
  },
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'top',
    x: 15,
    y: 25,
    itemDistance: 10,
    floating: true,
  },
  xAxis: {
    type: 'category',
    labels: {
      rotation: -45,
    },
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      format: '{text} kWh',
    },
    maxPadding: 0.01,
  },
  plotOptions: {
    column: {
      events: {
        legendItemClick: () => false,
      },
      stacking: 'normal',
    },
    spline: {
      events: {
        legendItemClick: () => false,
      },
    },
  },
  tooltip: {
    shared: true,
    valueDecimals: 2,
    valueSuffix: ' €',
  },
  series: [
    { name: 'Option Temps Réel', color: '#008ACD', data: [] },
    {
      name: 'Offre Référence',
      type: 'spline',
      color: '#00141E',
      data: [],
      marker: {
        enabled: false,
      },
    },
  ],
  credits: {
    enabled: false,
  },
};

const component = (props, ref) => {
  return <HighCharts ref={ref} init={initHC} />;
};

export const ConsumGraph = forwardRef(component);
