import React from 'react';
import { DataTable } from 'components';
import style from './style.module.scss';

const header = [
  '#',
  <>
    Prix total
    <br />
    Temps Réel
  </>,
  <>
    Prix total
    <br />
    Offre Référence
  </>,
  <>
    Fourniture
    <br />
    Temps Réel
  </>,
  <>
    Fourniture
    <br />
    Offre Référence
  </>,
  'Acheminement',
];

export const DayliDataTable = ({ body }) => <DataTable className={style.Table} header={header} body={body} />;
