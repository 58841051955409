import React from 'react';

import { DataTable } from 'components';

const header = [
  '#',
  'Gain',
  <>
    Tarif
    <br />
    Temps Réel
  </>,
  <>
    Tarif
    <br />
    Offre Référence
  </>,
];

export const BalanceDataTable = ({ body }) => {
  return <DataTable header={header} body={body} />;
};
