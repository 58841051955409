import React, { useEffect, useState } from 'react';

import style from './style.module.scss';

const SwitchOption = ({ setValue, label, value, isActive, className }) => {
  const handleClick = () => {
    setValue(value);
  };

  const classN = [style.Option, isActive && style.active, className].filter(Boolean).join(' ');

  return (
    <div className={classN} onClick={handleClick}>
      <span>{label}</span>
    </div>
  );
};

export const SwitchMultiOption = ({ onChange, options, defaultValue }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (value === undefined) return;
    if (onChange) onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const optionsList = options.map((opt, key) => (
    <SwitchOption key={key} setValue={setValue} isActive={value === opt.value} label={opt.label} value={opt.value} />
  ));

  return <div className={style.Container}>{optionsList}</div>;
};
