import React from 'react';

import style from './style.module.scss';

export const Spinner = () => (
  <div className={style.Container}>
    <div className={`nj-spinner ${style.Spinner}`} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);
