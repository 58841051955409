import { useEffect, useState } from 'react';

export const useClassName = (...propClassNames) => {
  const [className, setClassName] = useState('');

  useEffect(() => {
    setClassName(propClassNames?.filter((c) => Boolean(c) && c.trim().length).join(' '));
  }, [propClassNames]);

  return [className, setClassName];
};
