import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppWithRouterAccess } from 'AppWithRouterAccess';
import { UserProvider } from 'contexts';

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <AppWithRouterAccess />
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
