import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import style from './style.module.scss';

const durationFormatLabel = {
  d: (startDate) => startDate.format('D MMMM YYYY'),
  w: (startDate, endDate) => {
    if (startDate.month() === endDate.month()) {
      return 'du ' + startDate.format('D') + ' au ' + endDate.format('D MMM YYYY');
    }
    return 'du ' + startDate.format('D MMM') + ' au ' + endDate.format('D MMM YYYY');
  },
  M: (startDate) => startDate.format('MMMM YYYY'),
};

export const DateSwiper = ({ onChange, defaultValue, duration = 'd' }) => {
  const [startDate, setStartDate] = useState(dayjs(dayjs(defaultValue).format('YYYY-MM-DD')));
  const [endDate, setEndDate] = useState(dayjs());

  const previousAction = () => {
    setStartDate(startDate.subtract(1, duration));
  };
  const nextAction = () => {
    setStartDate(startDate.add(1, duration));
  };

  useEffect(() => {
    if (duration === 'w') {
      setStartDate(dayjs(startDate).subtract(startDate.weekday(), 'd'));
    } else if (duration === 'M') {
      setStartDate(dayjs(startDate).date(1));
    } else {
      setStartDate(dayjs(startDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  useEffect(() => {
    if (!startDate) return;

    const newEndDate = startDate.add(1, duration).subtract(1, 'ms');
    setEndDate(newEndDate);

    if (onChange) onChange(startDate, newEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  return (
    <div className={style.DatePicker}>
      <div className={style.DatePrevious} onClick={previousAction}>
        <i className="material-icons md--white">arrow_back_ios</i>
      </div>
      <div className={style.DateDisplay}>{durationFormatLabel[duration](startDate, endDate)}</div>
      <div className={style.DateNext} onClick={nextAction}>
        <i className="material-icons md--white">arrow_forward_ios</i>
      </div>
    </div>
  );
};
