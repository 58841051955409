import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useFetchApi } from 'hooks';
import { UserContext } from 'contexts';

import style from './style.module.scss';

export const Home = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { fetchApi } = useFetchApi();
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (authState?.isAuthenticated && !user?.username && fetchApi) {
      fetchApi('/info', { params: { uid: authState?.accessToken?.claims?.uid } }).then(setUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, fetchApi]);

  if (!authState) return null;

  const logout = async () => oktaAuth.signOut();

  return (
    <div className={style.Container}>
      <nav className="nj-navbar nj-navbar--shadow nj-navbar--expand-xl mb-3 sticky-top">
        <div className="container">
          <span className="nj-navbar__brand">
            <svg className="nj-navbar__logo">
              <use href="/logo-engie.svg#logo-engie" />
            </svg>
          </span>
        </div>
      </nav>
      <div className={`${style.LinkGrid} ${user?.Pilotage && 'pilot'}`}>
        <div className={style.LinkRow}>
          <Link className="nj-btn nj-btn--outline-primary" to="/dayli">
            <i className="nj-icon nj-icon-document-money" />
            <span>Prix</span>
          </Link>
          <Link className="nj-btn nj-btn--outline-primary" to="/consumption">
            <i className="nj-icon nj-icon-building-search" />
            <span>Conso</span>
          </Link>
        </div>
        <div className={style.LinkRow}>
          <Link className="nj-btn nj-btn--outline-primary" to="/balance">
            <i className="nj-icon nj-icon-juggle" />
            <span>Bilan</span>
          </Link>
          {user?.Pilotage && (
            <a
              className="nj-btn nj-btn--outline-primary"
              target="_blank"
              href="https://interconnect-engie.tiko.ch"
              rel="noreferrer"
            >
              <i className="nj-icon nj-icon-smartphone-home" />
              <span>Pilotage</span>
            </a>
          )}
        </div>
      </div>

      <div className={style.HelpContainer}>
        <button className="nj-btn nj-btn--primary logout" onClick={logout}>
          Déconnexion
        </button>
        <button className="nj-btn nj-btn--primary faq">Mon App ENGIE</button>
        <button className="nj-btn nj-btn--primary faq">FAQ</button>
        <button
          type="button faq"
          className="nj-btn nj-btn--primary faq"
          data-toggle="collapse"
          data-target="#collapseContact"
          aria-expanded="false"
          aria-controls="collapseContact"
        >
          Nous Contacter
        </button>
        <div className="nj-collapse" id="collapseContact">
          <div className="nj-card nj-card__body">
            Service relations clients
            <br />
            <a href="tel:0800730419">0 800 730 419</a>
            <a href="mailto:support.interconnect@engie.com">interconnect@engie.com</a>
          </div>
        </div>
        <a className="nj-btn nj-btn--primary faq" href="/cgu.pdf" target="_blank">
          CGU
        </a>
        <a className="nj-btn nj-btn--primary faq" href="/mentions.pdf" target="_blank">
          Mentions légales
        </a>
      </div>
    </div>
  );
};
