import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';

import { Balance, Consumption, DayliPrice, Home, Login } from 'screens';

import { oktaAuthConfig, oktaSignInConfig } from './config';

const oktaAuth = new OktaAuth(oktaAuthConfig);

export const AppWithRouterAccess = () => {
  const history = useHistory();

  const customAuthHandler = () => {
    history.push('/');
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    console.log('restoreOriginalUri', _oktaAuth, originalUri);
    //history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path="/" exact={true} render={() => <Login config={oktaSignInConfig} />} />
        <Route path="/login/callback" component={LoginCallback} />
        <Route path="/home" component={Home} />
        <SecureRoute path="/dayli" component={DayliPrice} />
        <SecureRoute path="/consumption" component={Consumption} />
        <SecureRoute path="/balance" component={Balance} />
      </Switch>
    </Security>
  );
};
