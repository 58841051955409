import React from 'react';

import style from './style.module.scss';

export const AlertOverlap = ({ children }) => (
  <div className={style.Container}>
    <div className={style.Message} role="status">
      {children}
    </div>
  </div>
);
