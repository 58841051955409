import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useOktaAuth } from '@okta/okta-react';

import { roundNumber } from 'helpers';

import { AlertOverlap, Card, Navbar, Spinner, SwitchMultiOption, SwitchViewData, TopBar } from 'components';
import { DayliDataTable } from './DayliDataTable';
import { DayliGraph } from './DayliGraph';

import style from './style.module.scss';

import { useFetchApi } from 'hooks';

const options = [
  { label: "Aujourd'hui", value: 0 },
  { label: 'Demain', value: 1 },
];

export const DayliPrice = () => {
  const [todayDate] = useState(dayjs());
  const [selectDate, setSelectDate] = useState(dayjs());
  const [isTomorrow, setIsTomorrow] = useState(false);
  const [currentHour, setCurrentHour] = useState({ label: '', value: '' });
  const [nextHour, setNexttHour] = useState({ label: '', value: '' });
  const [bodyData, setBodyData] = useState([]);
  const [hcSeries, setHcSeries] = useState([]);
  const [pricing, setPricing] = useState([]);

  const { authState } = useOktaAuth();

  const { fetchApi, loading } = useFetchApi();

  const dayChange = (value) => {
    setSelectDate(todayDate.add(value, 'd'));
    setIsTomorrow(Boolean(value));
  };

  useEffect(() => {
    if (!fetchApi) return;
    fetchApi('/prices', {
      params: {
        uid: authState?.accessToken?.claims?.uid,
        refresh: dayjs().subtract(1022, 'm').startOf('d').unix(),
      },
    }).then((json) => {
      setPricing(
        json?.map((v) => {
          v.date = dayjs(v.date);
          v.totalDynamique = v.acheminement + v.tarifDynamique;
          v.totalReference = v.acheminement + v.tarifReference;

          return v;
        }) || []
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchApi]);

  useEffect(() => {
    const data = [];
    const series = pricing.slice(0).reduce(
      (acc, curr, i, arr) => {
        if (curr.date.format('YYMMDD') === selectDate.format('YYMMDD')) {
          acc[0].push(curr.totalDynamique);
          acc[1].push(curr.totalReference);

          data.push([
            curr.date.format('H') + 'h',
            ...[
              curr.totalDynamique,
              curr.totalReference,
              curr.tarifDynamique,
              curr.tarifReference,
              curr.acheminement,
            ].map((v) => roundNumber(v).toFixed(2) + ' €'),
          ]);
        } else if (acc[0].length) arr.splice(1);
        return acc;
      },
      [[], []]
    );

    const cur = dayjs();
    if (isTomorrow && series[0].length) {
      let max = [];
      let min = [];
      series[0].forEach((v, hour) => {
        if (!max[1] || max[1] < v) max = [hour, v];
        if (!min[1] || min[1] > v) min = [hour, v];
      });
      setCurrentHour({
        label: (
          <span>
            Meilleur prix du jour
            <br />
            entre {min[0]}h - {min[0] + 1}h
          </span>
        ),
        value: <span style={{ color: '#6ED2B1' }}>{roundNumber(min[1])} €</span>,
      });
      setNexttHour({
        label: (
          <span>
            Prix le plus élevé du
            <br />
            jour entre {max[0]}h - {max[0] + 1}h
          </span>
        ),
        value: <span style={{ color: '#F78A31' }}>{roundNumber(max[1])} €</span>,
      });
    } else if (series[0][cur.hour()]) {
      setCurrentHour({
        label: `En ce moment`,
        value: `${roundNumber(series[0][cur.hour()])} €`,
      });

      if (series[0][cur.hour() + 1]) {
        setNexttHour({
          label: `${cur.hour() + 1}h - ${cur.hour() + 2}h`,
          value: `${roundNumber(series[0][cur.hour() + 1])} €`,
        });
      } else {
        setNexttHour({
          label: '',
          value: '',
        });
      }
    } else {
      setCurrentHour({
        label: '',
        value: '',
      });
      setNexttHour({
        label: '',
        value: '',
      });
    }

    setBodyData(data);
    setHcSeries(series);
  }, [pricing, selectDate, isTomorrow]);

  return (
    <>
      <TopBar>
        <SwitchMultiOption onChange={dayChange} options={options} defaultValue={0} />
      </TopBar>
      <main>
        <SwitchViewData dataComponent={<DayliDataTable body={bodyData} />}>
          <div className={style.CardContainer}>
            <Card preTitle={currentHour.label} postTitle={currentHour.value} />
            <Card preTitle={nextHour.label} postTitle={nextHour.value} />
          </div>
          <DayliGraph series={hcSeries} />
        </SwitchViewData>
        {!loading && !currentHour.value && <AlertOverlap>Les prix seront disponibles à partir de 17h</AlertOverlap>}
        {loading && <Spinner />}
      </main>
      <Navbar />
    </>
  );
};
