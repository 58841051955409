import { useRef } from 'react';

export const useDebounce = (callback, delay = 500) => {
  const callbackRef = useRef();

  if (!callbackRef.current) {
    callbackRef.current = (() => {
      let timeout;
      return (...args) => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(this, args), delay);
      };
    })();
  }

  return callbackRef.current;
};
