import React, { useEffect, useState } from 'react';

import { FabButton } from 'components/FabButton';

export const SwitchViewData = ({ className, dataComponent, children, onChange }) => {
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    if (onChange) onChange(showData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showData]);

  const handleClick = () => {
    setShowData(!showData);
  };

  return (
    <>
      <FabButton className={className} onClick={handleClick} icon={showData ? 'equalizer' : 'description'} />
      {showData ? dataComponent : children}
    </>
  );
};
