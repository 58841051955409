import React, { forwardRef } from 'react';
import { HighCharts } from 'components';

const initHC = {
  chart: {
    type: 'column',
  },
  plotOptions: {
    column: {
      events: {
        legendItemClick: () => false,
      },
    },
  },
  title: {
    text: 'Bilan en €',
    margin: 40,
  },
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'top',
    x: 15,
    y: 25,
    floating: true,
    labelFormatter: function () {
      return 'Gain <span style="float: left; font-size: 28px; color: #F78A31" y="19">●</span><span y="15"> Perte</span>';
    },
  },
  xAxis: {
    type: 'category',
    labels: {
      rotation: -45,
    },
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      format: '{text} €',
    },
    maxPadding: 0.01,
  },
  series: [{ name: 'Gain', type: 'column', color: '#6ED2B1', negativeColor: '#F78A31', data: [] }],
  tooltip: {
    formatter: function (tooltip) {
      let formatted = tooltip.defaultFormatter.call(this, tooltip);
      if (this.y < 0) formatted = formatted.map((txt) => txt.replace('Gain', 'Perte'));
      return formatted;
    },
    valueDecimals: 2,
    valueSuffix: ' €',
  },
  credits: {
    enabled: false,
  },
};

const component = (props, ref) => {
  return <HighCharts ref={ref} init={initHC} />;
};

export const BalanceChart = forwardRef(component);
