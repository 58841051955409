import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(localizedFormat);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.locale('fr');

export { dayjs };

export const roundNumber = (value, digit = 2) => {
  const pow = Math.pow(10, digit);
  return Math.round((value + Number.EPSILON) * pow) / pow;
};
