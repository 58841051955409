import React, { useEffect, useState } from 'react';

import { useClassName } from 'hooks';

export const Switch = ({
  className: classNameProp,
  onChange,
  checked: checkedProp = false,
  leftOption,
  rightOption,
  defaultValue,
}) => {
  const [checked, setChecked] = useState(checkedProp);
  const [className] = useClassName('nj-switch', classNameProp);

  const handleClick = (e) => {
    e.preventDefault();
    setChecked(!checked);
  };

  useEffect(() => {
    if (onChange) onChange(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <div className={className}>
      <label onClick={handleClick}>
        <input type="checkbox" readOnly checked={checked} />
        <span className="nj-switch__slider">
          <span className="nj-switch__slider-left">{leftOption}</span>
          <span className="nj-switch__slider-right">{rightOption}</span>
        </span>
      </label>
    </div>
  );
};
