import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useOktaAuth } from '@okta/okta-react';

import { useFetchApi } from 'hooks';
import { roundNumber } from 'helpers';

import { AlertOverlap, Navbar, Selector, Spinner, SwitchViewData } from 'components';
import { BalanceChart } from './BalanceChart';
import { BalanceDataTable } from './BalanceDataTable';

const plotIdByDuration = {
  d: (date) => date.hour(),
  w: (date) => date.date(),
  M: (date) => date.date(),
};

export const Balance = () => {
  const hc = useRef();
  const [selector, setSelector] = useState({});
  const [bodyData, setBodyData] = useState([]);
  const [conso, setConso] = useState([]);
  const { fetchApi, loading } = useFetchApi();
  const { authState } = useOktaAuth();

  const onDateChange = (startDate, endDate, duration) => {
    setSelector({ startDate, endDate, duration });
  };

  const fetchConso = () => {
    if (!fetchApi || !selector.startDate || !selector.endDate) return;
    fetchApi('/conso', {
      params: {
        from: selector.startDate.unix(),
        to: selector.endDate.unix(),
        uid: authState?.accessToken?.claims?.uid,
        refresh: selector.endDate < dayjs() ? selector.endDate.startOf('d').unix() : dayjs().startOf('h').unix(),
      },
    })
      .then((json) => {
        const remap =
          json.map((v) => {
            v.date = dayjs(v.date);
            return v;
          }) || [];

        setConso(remap);
      })
      .catch((error) => {
        if (error.status === 'cancelled') {
          console.error(error);
        }
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchConso, [fetchApi, selector.startDate, selector.endDate]);

  const computeData = () => {
    const { startDate, duration } = selector;

    if (!startDate) return;

    const data = new Map();
    for (const curr of conso) {
      const plotId = plotIdByDuration[duration](curr.date);
      const [, gain = 0, totalDynamique = 0, totalReference = 0] = data.get(plotId) || [];
      const timeFormatted =
        'd' === duration
          ? curr.date.format('H') + 'h'
          : curr.date.format('ddd')[0].toUpperCase() + curr.date.format('ddd D').slice(1);
      data.set(plotId, [
        timeFormatted,
        gain + curr.gain,
        totalDynamique + curr.coutDynamique,
        totalReference + curr.coutReference,
      ]);
    }

    setBodyData(
      Array.from(data.values()).map(([timeFormatted, gain, totalDynamique, totalReference]) => [
        timeFormatted,
        ...[gain, totalDynamique, totalReference].map((v) => roundNumber(v).toFixed(2) + ' €'),
      ])
    );

    if (!hc?.current?.chart) return;
    const computed = Array.from(data.values()).map(([timeFormatted, gain]) => [timeFormatted, gain]);
    hc.current.chart.series[0].setData(computed);
  };
  useEffect(computeData, [conso, selector]);

  return (
    <>
      <Selector onChange={onDateChange} de />
      <main>
        <SwitchViewData onChange={computeData} dataComponent={<BalanceDataTable body={bodyData} />}>
          <BalanceChart ref={hc} />
        </SwitchViewData>
        {loading && <Spinner />}
        {!loading && !conso.length && <AlertOverlap>Pas de données disponibles</AlertOverlap>}
      </main>

      <Navbar />
    </>
  );
};
