import React from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { OktaSignInWidget } from 'components';

export const Login = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth();

  const onSuccess = (tokens) => {
    oktaAuth.handleLoginRedirect(tokens);
    if (tokens.accessToken?.claims?.uid) {
      // eslint-disable-next-line no-undef
      gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
        user_id: tokens.accessToken?.claims?.uid,
      });
    }
  };

  const onError = (err) => {
    console.log('error logging in', err);
  };

  if (!authState) return null;

  return authState.isAuthenticated ? (
    <Redirect to={{ pathname: '/home' }} />
  ) : (
    <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
  );
};
