import React from 'react';

import { DataTable } from 'components';

import style from './style.module.scss';

const header = [
  '#',
  'kWh',

  <>
    Prix total
    <br />
    Temps Réel
  </>,
  <>
    Prix total
    <br />
    Offre Référence
  </>,
  <>
    Fourniture
    <br />
    Temps Réel
  </>,
  <>
    Fourniture
    <br />
    Offre Référence
  </>,
  'Acheminement',
];

export const ConsumDataTable = ({ body }) => {
  return <DataTable className={style.Table} header={header} body={body} />;
};
