import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'hooks';

import { DateSwiper } from '../DateSwiper';
import { SwitchMultiOption } from '../SwitchMultiOption';
import { TopBar } from '../TopBar';

import style from './style.module.scss';

const options = [
  { label: 'Jour', value: 'd' },
  { label: 'Semaine', value: 'w' },
  { label: 'Mois', value: 'M' },
];

export const Selector = ({ onChange, defaultStartDate, duration: durationProp = 'd' }) => {
  const [duration, setDuration] = useState(durationProp);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState();
  const refOnChange = useRef();

  const onDateChange = (newStartDate, newEndDate) => {
    if (startDate !== newStartDate) setStartDate(newStartDate);
    if (endDate !== newEndDate) setEndDate(newEndDate);
  };

  const debOnChange = useDebounce((...args) => {
    if (refOnChange.current) refOnChange.current(...args);
  }, 200);

  useEffect(() => {
    refOnChange.current = onChange;
  }, [onChange]);

  useEffect(() => {
    debOnChange(startDate, endDate, duration);
  }, [debOnChange, startDate, endDate, duration]);

  return (
    <TopBar className={style.Selector}>
      <SwitchMultiOption options={options} defaultValue={durationProp} onChange={setDuration} />
      <DateSwiper onChange={onDateChange} duration={duration} defaultValue={startDate} />
    </TopBar>
  );
};
