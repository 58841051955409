import React from 'react';
import { NavLink } from 'react-router-dom';

import style from './style.module.scss';

export const Navbar = () => {
  return (
    <nav className={`nj-navbar nj-navbar--shadow nj-navbar--expand position-fixed fixed-bottom ${style.NavBar}`}>
      <ul className="nj-navbar__nav d-flex justify-content-around ">
        <li className="nj-navbar__nav-item">
          <NavLink className="nj-navbar__nav-link" exact to="/home">
            <i className="nj-icon nj-icon-insulation" />
            <span>Home</span>
          </NavLink>
        </li>
        <li className="nj-navbar__nav-item">
          <NavLink className="nj-navbar__nav-link" exact to="/dayli">
            <i className="nj-icon nj-icon-document-money" />
            <span>Prix</span>
          </NavLink>
        </li>
        <li className="nj-navbar__nav-item">
          <NavLink className="nj-navbar__nav-link" exact to="/consumption">
            <i className="nj-icon nj-icon-building-search" />
            <span>Conso</span>
          </NavLink>
        </li>
        <li className="nj-navbar__nav-item">
          <NavLink className="nj-navbar__nav-link" exact to="/balance">
            <i className="nj-icon nj-icon-juggle" />
            <span>Bilan</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};
